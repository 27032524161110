import {reactive} from 'vue';
import backendApi from "@/backend-api";
import TripDetailsAndPackets from "@/models/trip/TripDetailsAndPackets";
import TripPacket from "@/models/trip/TripPacket";
import ServiceInTrip from "@/models/trip/ServiceInTrip";
import TripRecord from "@/models/trip/TripRecord";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import ResponseMessage from "@/models/trip/ResponseMessage";
import UserData from "@/models/UserData";

const state = reactive({
  tripRecords: [] as TripRecord[],
  tripRecordsErrors: [] as TripRecordErrors[],
  trip: {} as TripDetailsAndPackets,
  selectedPacket: {} as TripPacket,
  messageInfo: {} as ResponseMessage,
  message: {} as ResponseMessage,
  loading: false,
  bonuses: 0 // оплата выезда
})

const getNextFreeNegativeId = () => {
  if (state.tripRecords.length == 0) {
    return -1
  }

  const lastRecord = state.tripRecords.reduce((record1, record2) =>
      (record1.id < record2.id) ? record1 : record2)

  return lastRecord.id > 0
      ? -1
      : lastRecord.id - 1
}

const newTripRecord = (name: string, surname: string, phone: string): TripRecord => {
  state.tripRecords = [...state.tripRecords, {
    id: getNextFreeNegativeId(),
    name: name,
    surname: surname,
    phone: phone,
    comment: "",
    selectedPacket: {} as TripPacket,
    selectedServices: [] as ServiceInTrip[],
    paidBonuses: 0,
    prepaidSum: 0,
    discountSum: 0,
    fullPrice: 0
  } as TripRecord]

  return state.tripRecords[state.tripRecords.length - 1]
}

const updateData = (newInfo: TripDetailsAndPackets) => {
  if (Object.keys(newInfo).length == 0) {
    return
  }

  state.trip = newInfo
  state.messageInfo = newInfo.message

  const ids = state.tripRecords.map(it => it.id)

  const orderedTripRecords = newInfo.orderedTripRecords.filter(otr => !ids.includes(otr.id))

  const filteredTripRecords = state.tripRecords.filter(it =>
      it.selectedPacket.tripId == newInfo.details.id || Object.keys(it.selectedPacket).length == 0
  )

  state.tripRecords = (orderedTripRecords).concat(filteredTripRecords)
}

const resetSelectedPacket = () => {
  state.tripRecords.forEach(it => {
    if (it.id < 0) {
      it.selectedPacket = {} as TripPacket;
      it.selectedServices = [] as ServiceInTrip[]
    }
  })
}

const fetchTripDetailsAndPackets = async (id: number) => {
  try {
    state.loading = true

    const response: TripDetailsAndPackets = await backendApi.fetchTripDetailsAndPackets(id)

    updateData(response)
  } finally {
    state.loading = false
  }
}

const editFirstTripRecordByUserData = (userData: UserData) => {
  if (state.tripRecords[0] == undefined) {
    return
  }

  if (state.tripRecords[0].name == undefined || state.tripRecords[0].name == null || state.tripRecords[0].name == "") {
    state.tripRecords[0].name = userData.name
  }
  if (state.tripRecords[0].surname == undefined || state.tripRecords[0].surname == null || state.tripRecords[0].surname == "") {
    state.tripRecords[0].surname = userData.surname
  }
  if (state.tripRecords[0].phone == undefined || state.tripRecords[0].phone == null || state.tripRecords[0].phone == "") {
    state.tripRecords[0].phone = userData.phone
  }

  if (state.tripRecords[0].weight == undefined || state.tripRecords[0].weight == null) {
    state.tripRecords[0].weight = userData.weight
  }
  if (state.tripRecords[0].height == undefined || state.tripRecords[0].height == null) {
    state.tripRecords[0].height = userData.height
  }
  if (state.tripRecords[0].footSize == undefined || state.tripRecords[0].footSize == null) {
    state.tripRecords[0].footSize = userData.footSize
  }
  if (state.tripRecords[0].angles == undefined || state.tripRecords[0].angles == null || state.tripRecords[0].angles == "") {
    state.tripRecords[0].angles = userData.angles
  }
}

const getSelectedServicesPrice = (selectedServices: ServiceInTrip[]) => {
  let sum = 0;
  selectedServices
      ?.filter(s => !s.mustHave)
      ?.forEach(a => sum += a.price)

  return sum
}

const getTotalPrice = () => {
  if (state.tripRecords == undefined) {
    return 0
  }

  const result = state.tripRecords.map(tripRecord => {
        if (!tripRecord.selectedPacket.id) {
          return 0
        }
        if (tripRecord.id > 0) {
          return tripRecord.fullPrice - tripRecord.paidBonuses - tripRecord.discountSum // todo фиксить!
        }

        return tripRecord.selectedPacket.price
            + getSelectedServicesPrice(tripRecord.selectedServices)
            - tripRecord.paidBonuses
            - tripRecord.prepaidSum
      }
  ).reduce((a, b) => a + b, 0)

  return result
}

const getFullPrepaidSum = () => {
  return state.tripRecords.map(tripRecord => {
    return tripRecord.id > 0 ? tripRecord.prepaidSum : 0
  }).reduce((a, b) => a + b, 0)
}

const removeTripRecord = (tripRecord: TripRecord) => {
  state.tripRecords = state.tripRecords.filter(rp => rp !== tripRecord)
}

const sendTripRecords = async () => {
  state.loading = true

  const resp = await backendApi.sendTripRecords({
    tripId: state.trip.details.id,
    tripRecords: state.tripRecords,
    bonuses: state.bonuses
  })

  state.loading = false

  if (!resp.success) {
    state.message = {
      title: "Ошибка",
      text: resp.message,
      success: false
    }
  }

  if (resp.success && resp.tripDetailsAndPackets) {
    state.trip = resp.tripDetailsAndPackets
    state.messageInfo = resp.tripDetailsAndPackets.message
    state.tripRecords = resp.tripDetailsAndPackets.orderedTripRecords
  }

  return resp
}

const payWithTripBonuses = (bonuses: number) => {
  state.bonuses = bonuses
}

const clearMessage = () => {
  state.message = {} as ResponseMessage
}

export default {
  state,
  fetchTripDetailsAndPackets,
  editFirstTripRecordByUserData,
  getTotalPrice,
  getFullPrepaidSum,
  newTripRecord,
  resetSelectedPacket,
  removeTripRecord,
  sendTripRecords,
  payWithTripBonuses,
  clearMessage
}
