<template>
  <div>
    <Header
        class="header-top"
        :show-login-info="true"
    >
      <Burger/>
    </Header>
    <slot/>
  </div>
</template>

<script>
  import Header from "@/components/Header.vue"
  import {defineComponent} from "vue";
  import Burger from "@/components/menu/Burger.vue";

  export default defineComponent({
    name: 'ContentLayout',
    components: {
      Header,
      Burger
    }
  });
</script>
