<template>
  <div v-if="message.text" class="auth-warning">
    <div class="clearfix">
      <div style="float: left;">
        <h4>
          <i
              v-if="isInfo"
              class="bx bxs-info-circle message__info-icon"
          />
          <i
              v-if="!message.success"
              class="bx bx-error-circle message__error-icon"
          />
          <span v-if="message.success">{{ message.title }}</span>
          <span v-else>Ошибка</span>
        </h4>
      </div>
      <div style="float: right;" v-if="!isInfo">
        <i
            class="bx bx-x message__clean-icon"
            @click="clearMessage"
        />
      </div>
    </div>
    <div v-html="message.text "/>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import ResponseMessage from "@/models/trip/ResponseMessage";

export default defineComponent({
  name: 'MessageComponent',
  props: {
    message: {
      type: Object as PropType<ResponseMessage>,
      required: true
    },
    isInfo: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onClearMessage: null,
  },
  setup(props, {emit}) {
    const clearMessage = () => {
      emit("onClearMessage")
    }

    return {
      clearMessage
    }
  }
})
</script>

<style scoped>
.auth-warning {
  border-radius: 0.25rem;
  padding: 20px;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 2rem;
}

.message__info-icon {
  margin-right: 5px;
  color: #18d26e;
}

.message__error-icon {
  margin-right: 5px;
  color: #d31515
}

.message__clean-icon {
  color: #fff;
  font-size: 38px;
  cursor: pointer;
}

</style>
