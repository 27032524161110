<template>
  <div>
    <div class="burger-first">
      <Burger/>
    </div>
    <Header/>
    <slot/>
  </div>
</template>

<script>
  import Header from "@/components/Header.vue"
  import Burger from "@/components/menu/Burger.vue";

  export default {
    name: 'FirstLayout',
    components: {
      Header,
      Burger,
      // Sidebar
    }
  }
</script>

<style scoped>
  .burger-first {
    align-items: center;
    float: right;
    height: 60px;
    display: flex;
    margin-right: 10px
  }
</style>
