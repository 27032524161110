<template>
  <section id="trip" class="trip section-show" style="height: auto!important;">
    <div class="container">

      <div class="section-title">
<!--        <h2>Выезды</h2>-->
        <p v-if="hasActualTrips()">Текущие выезды</p>
      </div>

      <!--      <div class="row">-->
      <!--        <div class="col-lg-12 d-flex justify-content-center">-->
      <!--          <ul id="trip-flters">-->
      <!--            <li data-filter="*" class="filter-active">All</li>-->
      <!--            <li data-filter=".евразия">Евразия</li>-->
      <!--            <li data-filter=".filter-app">Завьялиха</li>-->
      <!--            <li data-filter=".filter-card">Аджигардак</li>-->
      <!--          </ul>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row trip-container" v-if="hasActualTrips()">
        <div
            v-for="actualTrip in state.list.actualTrips"
            class="col-lg-4 col-md-6 d-flex trip-item евразия "
            :key="actualTrip.id"
        >
          <div class="trip-info">
            <div class="trip-info__message">

              <div
                  v-if="actualTrip.alreadyRecorded"
                  class="trip-info__message-element"
              >
                <i class="bx bx-info-circle"/>
                <span v-if="actualTrip.inPrepaidList">
                    В резерве. Необходима предоплата
                </span>
                <span v-else>
                    Вы записаны на выезд
                </span>
              </div>


              <div
                  v-if="!actualTrip.openedCheckIn"
                  class="trip-info__message-element">
                <i class="bx bx-error-circle"/>
                Регистрация на выезд закрыта
              </div>
            </div>
            <div class="trip-info__prev">
              <div class="trip-info__prev-img">
                <router-link :to="{name: 'tripDetails',  params: { id: actualTrip.id }}">
                  <keep-alive>
                    <img
                        :src="getImageUrl(actualTrip.id)"
                        class="img-fluid" alt=""
                    >
                  </keep-alive>
                </router-link>
              </div>
            </div>
            <div class="trip-info__footer">
              <div class="clearfix">
                <div class="trip-info__members">
                  <router-link :to="{name: 'riders', params: { id: actualTrip.id }}" style="color: white;">
                    <i class="bx bxs-user"/>
                    {{ actualTrip.members }} / {{ actualTrip.maxMembers }}
                  </router-link>
                </div>

                <div class="trip-info__details-link">
                  <router-link :to="{name: 'tripDetails',  params: { id: actualTrip.id }}">
                    Подробнее >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>Планируем новые приключения. Скоро вернемся :)</h3>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount} from "vue";
import tripListStore from "@/store/trip-list"

export default defineComponent({
  name: 'TripList',
  setup() {
    const {
      state,
      hasActualTrips,
      fetchList
    } = tripListStore

    onBeforeMount(() => fetchList())

    const getImageUrl = (tripId: number) => {
      return "/api/image/displayTripImage/" + tripId
    }

    return {
      state,
      hasActualTrips,
      getImageUrl
    }
  }
})
</script>

<style scoped>

.trip-info__prev {
  min-height: 280px;
}


.trip-info__footer {
  padding: 0.9rem
}

.trip-info__members {
  font-size: 1.3rem;
  float: left;
}

.trip-info__message {
  position: absolute;
  width: 100%;
  font-size: 1.3rem;
}

.trip-info__message-element {
  background: rgba(44, 41, 41, 0.69);
  padding: 0.7rem;
}


.trip-info__details-link {
  cursor: pointer;
  font-size: 1.3rem;
  float: right
}

.trip-info {
  width: 100%;
  position: relative;
  background: rgba(204, 204, 204, 0.1);
  /* padding: 80px 20px; */
  transition: all ease-in-out 0.3s;
}

.clearfix:after {
  content: "\00A0";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block
}
</style>
