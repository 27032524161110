import {reactive} from 'vue'
import ZavbusService from "@/models/ZavbusService";

const zavbusServicesState = reactive({
  services: [
    {
      title: 'Зимний прокат',
      code: 'winter-rent',
      description: "Сноуборды, ботинки, лыжи, шлемы и другая экипировка",
      imagePath: "/api/image/display?image=winter-rent",
      priceImagePath: "/api/image/display?image=price-winter-rent"
    },
    {
      title: 'Зимний сервис',
      code: 'winter-service',
      description: "Обслуживание вашего зимнего инвентаря",
      imagePath: "/api/image/display?image=winter-service",
      priceImagePath: "/api/image/display?image=price-winter-service"
    }
    // {
    //   title: 'Летний прокат',
    //   code: 'summer-rent',
    //   description: "Велосипеды, сап-борды, палатки, рюкзаки и другая походная утварь",
    //   imagePath: "/api/image/display?image=summer-rent",
    //   priceImagePath: "/api/image/display?image=price-summer-rent",
    // },˚
    // {
    //   title: 'Летний сервис',
    //   code: 'summer-service',
    //   description: "Техническое обслуживание ваших велосипедов",
    //   imagePath: "/api/image/display?image=summer-service",
    //   priceImagePath: "/api/image/display?image=price-summer-service",
    // }
  ] as ZavbusService[]
})

const actions = {}

const getServiceByCode = (code: string): ZavbusService => {
  return zavbusServicesState.services.filter(it => it.code == code)[0]
}

export default {
  zavbusServicesState,
  getServiceByCode,
  ...actions,
}

