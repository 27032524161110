
import {defineComponent, onBeforeMount} from "vue";
import tripListStore from "@/store/trip-list"

export default defineComponent({
  name: 'TripList',
  setup() {
    const {
      state,
      hasActualTrips,
      fetchList
    } = tripListStore

    onBeforeMount(() => fetchList())

    const getImageUrl = (tripId: number) => {
      return "/api/image/displayTripImage/" + tripId
    }

    return {
      state,
      hasActualTrips,
      getImageUrl
    }
  }
})
