<template>
  <div>
    <div v-if="authState.userData.username">
      <img
          :src="authState.userData.photo100"
          class="img-circle"
          height="45"
          width="45"
      >

      <div class="login-data">
        <div class="login-data__username">
          {{ authState.userData.riderFullName }}
        </div>
        <div class="login-data__bonuses">
          <router-link :to="{ name: 'riderbonuses' }">
            {{ authState.userData.displayedBonuses }}
          </router-link>
        </div>
      </div>

      <i class="bx bx-power-off logout" @click="logout"/>
    </div>
    <div v-else-if="authState.userData.vkLink" @click="authByVk" style="cursor: pointer">
      Авторизация через VK
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import authStore from "@/store/auth.ts"

export default defineComponent({
  name: 'LoginInfo',
  setup() {
    const {
      authState,
      authByVk,
      logout
    } = authStore

    return {
      authByVk,
      authState,
      logout
    }
  }
})
</script>

<style scoped>
.img-circle {
  border-radius: 50%;
}

.login-data {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 15px;
}

.login-data__username {
  font-weight: 700
}

.login-data__bonuses {
  font-size: 12px
}

.logout {
  vertical-align: middle;
  color: #d9534f !important;
  font-size: 30px;
  cursor: pointer;
}
</style>
