
import {defineComponent, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import tripRecordsStore from "@/store/trip-records";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: 'TripPageLayout',
  components: {Loader},
  setup: function () {
    const {
      tripDetailsState,
      fetchTripDetails
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
      await fetchTripDetails(tripId.value)
    })

    return {
      tripId,
      tripDetailsState
    }
  },
})
