import {reactive} from 'vue';
import backendApi from "@/backend-api";
import TripDetails from "@/models/TripDetails";
import TripRiderListItem from "@/models/TripRiderListItem";
import TripRiderList from "@/models/TripRiderList";

const tripDetailsState = reactive({
  details: {} as TripDetails,
  riders: [] as TripRiderListItem[]
})


const updateData = (newInfo: TripRiderList) => {
  if (Object.keys(newInfo).length == 0) {
    return
  }

  tripDetailsState.details = newInfo?.details
  tripDetailsState.riders = newInfo?.riders
}

const fetchTripDetails = async (id: number) => {
  updateData({} as TripRiderList)

  const response: TripRiderList = await backendApi.fetchTripDetails(id)

  updateData(response)
}

export default {
  tripDetailsState ,
  fetchTripDetails
}
