
import {defineComponent, onMounted, PropType, ref, watch} from 'vue'
import TripRecordTitleComponent from "@/views/TripDetails/components/TripRecordTitleComponent.vue";
import ServiceInTripComponent from "@/views/TripDetails/components/ServiceInTripComponent.vue";
import TripPacketComponent from "@/views/TripDetails/components/TripPacketComponent.vue";
import TripRecord from "@/models/trip/TripRecord";
import tripDetailsStore from "@/store/trip-details";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import TripPacket from "@/models/trip/TripPacket";
import ServiceInTrip from "@/models/trip/ServiceInTrip";
import FieldErrorIconComponent from "@/views/TripDetails/components/FieldErrorIconComponent.vue";
import _ from 'lodash'
import TripMustHaveServicesComponent from "@/views/TripDetails/components/TripMustHaveServicesComponent.vue";

export default defineComponent({
  name: 'TripRecord',
  props: {
    tripRecordIndex: {
      type: Number,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    },
    tripRecordErrors: {
      type: Object as PropType<TripRecordErrors>,
      required: false
    },
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: false
    },
    isLoggedIn : {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:tripRecord', 'onConfirmTripRecord'],
  methods: {
    confirmCurrentRider() {
      this.$emit('onConfirmTripRecord')
    }
  },
  components: {
    TripRecordTitleComponent,
    TripPacketComponent,
    ServiceInTripComponent,
    FieldErrorIconComponent,
    TripMustHaveServicesComponent
  },
  setup(props, {emit}) {
    const {
      state
    } = tripDetailsStore

    const needRent = ref(false)

    watch(() => _.cloneDeep(props.tripRecord), (it) => {
      if (it != null) {
        needRent.value = it.selectedServices.some(it => it.rent)
            || it.selectedPacket?.services?.some(it => it.mustHave && it.rent)
      }
    })

    const updateField = (key: keyof TripRecord, value: any) => {
      emit('update:tripRecord', {...props.tripRecord, [key]: value})
    }

    const setSelectedPacket = (selectedPacket: TripPacket) => {
      emit('update:tripRecord', {
        ...props.tripRecord,
        ["selectedPacket"]: selectedPacket,
        ["selectedServices"]: []
      })
    }

    const setSelectedService = (selectedService: ServiceInTrip) => {
      const index = props.tripRecord?.selectedServices?.indexOf(selectedService)

      if (index === undefined) {
        return
      }

      if (index < 0) {
        props.tripRecord?.selectedServices.push(selectedService)
      } else {
        props.tripRecord?.selectedServices.splice(index, 1);
      }
    }

    const isSelectedService = (service: ServiceInTrip): boolean => {
      const index = props.tripRecord?.selectedServices?.indexOf(service)

      return index !== undefined && index > -1;
    }

    onMounted(async () => {
      if (state.trip.packets.length === 1) {
        setSelectedPacket(state.trip.packets[0])
      }
    })

    return {
      state,
      isSelectedService,
      setSelectedPacket,
      setSelectedService,
      updateField,
      needRent
    }
  }
})
