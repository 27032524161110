
import {defineComponent, PropType} from "vue";
import ZavbusService from "@/models/ZavbusService";

export default defineComponent({
  name: 'Services',
  props: {
    zavbusService: {
      type: Object as PropType<ZavbusService>,
      required: true
    }
  }
})
