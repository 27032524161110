<template>
  <div>
    <AppLayout>
      <router-view/>
    </AppLayout>
  </div>
</template>

<script lang="ts">
  import {defineComponent} from "vue"
  import AppLayout from "@/layouts/AppLayout.vue"

  export default defineComponent({
    name: 'app',
    components: {
      AppLayout
    },
  });
</script>

<style>
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }

  body {
    font-family: "Raleway", sans-serif;
  }

  .text-green {
    color: #18d26e;
  }

  .text-red {
    color: #d31515
  }

</style>
