<template>
  <section id="services" class="services section-show" style="height: auto!important;">
    <div class="container">

      <div class="section-title">
        <h2>Услуги</h2>
        <p>Прокат и Сервис</p>
      </div>

      <div class="row">
        <ServiceItem
            v-for="(zavbusService, index) in zavbusServicesState.services"
            :key="index"
            class="mt-4 "
            :zavbus-service='zavbusService'
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ServiceItem from "@/views/ZavbusServiceList/components/ServiceItem.vue";
import zavbusServicesStore from "@/store/zavbus-services";

export default defineComponent({
  name: 'Services',
  components: {
    ServiceItem
  },
  setup: function () {
    const {
      zavbusServicesState
    } = zavbusServicesStore

    return {
      zavbusServicesState
    }
  }
})
</script>

<style>
.services .service-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  /* padding: 80px 20px; */
  transition: all ease-in-out 0.3s;
}

.service-box__info h4 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.service-box__info p {
  font-family: "Raleway", sans-serif;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

</style>
