
import {defineComponent, PropType} from 'vue'
import TripRecord from "@/models/trip/TripRecord";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import FieldErrorIconComponent from "@/views/TripDetails/components/FieldErrorIconComponent.vue";
import TripDetails from "@/models/TripDetails";

export default defineComponent({
  name: 'TripRecordTitleComponent',
  props: {
    tripDetails: {
      type: Object as PropType<TripDetails>,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    },
    tripRecordIndex: {
      type: Number,
      required: true
    },
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: true
    },
    tripRecordErrors: {
      type: Object as PropType<TripRecordErrors>,
      required: false
    },
  },
  components: {
    FieldErrorIconComponent
  },
  emits: ['onRemoveRider'],
  setup(props, {emit}) {
    const displaySelectedPacketAndServices = (tripRecord: TripRecord) => {
      if (props.showDetails) {
        return ""
      }

      if (!tripRecord.selectedPacket.id) {
        return "Выберите программу"
      }

      const servicesNames = tripRecord.selectedServices
          ?.filter(it => !it.mustHave)
          .map(it => it.name)
          .join(", ")

      return tripRecord.selectedPacket.name + (servicesNames ? " + " + servicesNames : "")
    }

    const getTotalRiderPrice = (tripRecord: TripRecord) => {
      if (tripRecord.id > 0) {
        return tripRecord.fullPrice
      }

      if (!tripRecord.selectedPacket.id) {
        return 0;
      }

      let sum = 0;
      tripRecord.selectedServices
          ?.filter(s => !s.mustHave)
          ?.forEach(a => sum += a.price)

      return tripRecord.selectedPacket.price + sum;
    }

    const getForPayment = (tripRecord: TripRecord) => {
      return getTotalRiderPrice(tripRecord) - tripRecord.discountSum - tripRecord.paidBonuses
    }

    const getDisplayedTotalRiderPrice = (tripRecord: TripRecord) => {
      const totalRiderPrice = getTotalRiderPrice(tripRecord)
      const forPayment = getForPayment(tripRecord)

      let priceInfo = `${forPayment} ₽`
      if (totalRiderPrice != forPayment) {
        priceInfo = `<s> ${getTotalRiderPrice(tripRecord)}</s>&nbsp` + priceInfo
      }

      return priceInfo
    }

    const getDiscountInfo = (tripRecord: TripRecord) => {
      const result: string[] = [];

      if (tripRecord.paidBonuses) {
        result.push(tripRecord.displayedPaidBonuses)
      }

      if (tripRecord.discountSum) {
        result.push(`скидка ${tripRecord.discountSum} ₽`)
      }

      return result.join(", ")
    }

    const emitOnRemoveRider = (tripRecord: TripRecord) => {
      emit("onRemoveRider", tripRecord)
    }

    return {
      displaySelectedPacketAndServices,
      getTotalRiderPrice,
      getDisplayedTotalRiderPrice,
      getDiscountInfo,
      emitOnRemoveRider
    }
  }
})
