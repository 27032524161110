<template>
  <section id="service" class="service section-show" style="height: auto!important;">
    <div class="container">

      <div class="section-title">
        <h2> Услуги </h2>
        <p> {{ service.title }} </p>
      </div>

      <div class="row" v-if="service.priceImagePath">
        <img :src="service.priceImagePath" class="img-fluid" alt="">
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useRoute} from "vue-router";
import zavbusServicesStore from "@/store/zavbus-services";

export default defineComponent({
  name: 'ZavbusServicePage',
  props: {
    serviceCode: {
      type: String,
      required: true
    }
  },
  setup: function () {
    const code = useRoute().params.code as string

    const {
      getServiceByCode
    } = zavbusServicesStore

    const service = getServiceByCode(code)

    return {
      service
    }
  }
})
</script>

<style scoped>


</style>
