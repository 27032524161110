
import {defineComponent} from "vue";
import {useRoute} from "vue-router";
import zavbusServicesStore from "@/store/zavbus-services";

export default defineComponent({
  name: 'ZavbusServicePage',
  props: {
    serviceCode: {
      type: String,
      required: true
    }
  },
  setup: function () {
    const code = useRoute().params.code as string

    const {
      getServiceByCode
    } = zavbusServicesStore

    const service = getServiceByCode(code)

    return {
      service
    }
  }
})
