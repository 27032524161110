
import {defineComponent, PropType} from 'vue'
import ResponseMessage from "@/models/trip/ResponseMessage";

export default defineComponent({
  name: 'MessageComponent',
  props: {
    message: {
      type: Object as PropType<ResponseMessage>,
      required: true
    },
    isInfo: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    onClearMessage: null,
  },
  setup(props, {emit}) {
    const clearMessage = () => {
      emit("onClearMessage")
    }

    return {
      clearMessage
    }
  }
})
