<template>

  <div class="col-lg-4 col-md-6 mb-4 d-flex">
    <div class="trip-packet "
         :class="{'trip-packet-selected': selectedPacket?.id === packet.id }">
      <h5 class="packet-title">
        {{ packet.name }}
      </h5>
      <span class="packet-price">
        {{ packet.price }} ₽
      </span>
    </div>

    <div class="trip-packet__must-have-services"
         v-if="selectedPacket?.id === packet.id && selectedPacket.services?.filter(it => it.mustHave)">
      <ul>
        <li
            v-for="service in selectedPacket?.services?.filter(it => it.mustHave)"
            :key="service.id"
        >
          {{ service.name }}
        </li>
      </ul>
    </div>
  </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import TripPacket from "@/models/trip/TripPacket";

export default defineComponent({
  name: 'TripPacketComponent',
  props: {
    packet: {
      type: Object as PropType<TripPacket>,
      required: true
    },
    selectedPacket: {
      type: Object as PropType<TripPacket>,
      required: false
    }
  }
})
</script>

<style scoped>
.trip-packet {
  flex: 1 1 auto;
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.44);
  padding: 20px 20px;

  word-wrap: break-word;
  position: relative;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
  display: block;
}

.packet-price {
  font-size: 1.75rem;
  color: #18d26e;
}

.trip-packet-selected {
  color: #000000;
  background: #18d26e;
}

.trip-packet-selected .packet-price {
  color: #000000;
}


@media only screen and (max-width: 768px) {
  .d-flex {
    display: block !important;
  }

}

@media only screen and (min-width: 768px) {
  .trip-packet__must-have-services {
    margin-top: 1rem;
    display: none;
  }

}

ul {
  margin-top: 1rem;
  margin-bottom: 0;
}


</style>
