
import {defineComponent, PropType} from 'vue'
import TripRecord from "@/models/trip/TripRecord";

export default defineComponent({
  name: 'ConfirmTripRecordItem',
  props: {
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: true
    },
    selectedServices: {
      type: String,
      required: false
    }
  }
})
