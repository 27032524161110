import TripList from "@/models/TripList";
import {reactive} from 'vue';
import backendApi from "@/backend-api";

const state = reactive({
  list: {} as TripList
})

const actions = {
  async fetchList() {
    state.list = await backendApi.fetchTripList()
  }
}

const hasActualTrips = () => {
  return state.list.actualTrips && state.list.actualTrips.length > 0
}

export default {
  state,
  hasActualTrips,
  ...actions
}
