<template>
  <component :is="layout">
    <slot/>
  </component>
</template>

<script>
import AppLayoutDefault from './AppLayoutDefault'
import {onBeforeMount} from "vue";
import authStore from "@/store/auth.ts"

export default {
  name: "AppLayout",
  data: () => ({
    layout: AppLayoutDefault
  }),
  setup() {
    onBeforeMount(() => authStore.fetchUserData())
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        this.layout = route.meta.layout || AppLayoutDefault
      }
    }
  }
}
</script>
