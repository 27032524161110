<template>
  <div class="sidebar" @click="initBurgerMenu">
    <div
        class="sidebar-backdrop"
        v-if="isNavOpen">
    </div>
    <transition name="slide">
      <div v-if="isNavOpen"
           class="sidebar-panel">
        <slot name="login-info"/>
        <hr/>
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import {computed} from "vue";
  import store from "@/store";

  export default {
    name: "Sidebar",
    data: () => ({
    }),
    methods: {
      close() {
        store.dispatch("initBurgerMenu")
      }
    },
    setup() {
      const isNavOpen = computed(() => store.state.isNavOpen)

      const initBurgerMenu = () => {
        store.dispatch("initBurgerMenu")
      }

      return {
        isNavOpen,
        initBurgerMenu
      }
    }
  }
</script>

<style scoped>
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
  }

  .sidebar-backdrop {
    background-color: rgba(0, 0, 0, .5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .sidebar-panel {
    overflow-y: auto;
    background-color: #000000;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 100%;
  }
</style>
