
import {defineComponent} from "vue";
import tGisMap from './tGisMap.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    tGisMap
  }
})
