import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
// import store from "@/store";

// declare module 'axios' {
//   interface AxiosResponse<T = any> extends Promise<T> {
//   }
// }
//
// export interface HttpResponse<T> extends AxiosResponse {
//   data: T;
// }

function execRequest(axiosFunc: () => void) {
  try {
    return axiosFunc();
  } catch (e) {
    // handleError(e);
    return Promise.reject(e)
  }
}

const timeout = 60 * 1000;
const longTimeout = 2 * 60 * 60 * 1000;

export class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      timeout: timeout
    });

    this._requestInterceptor();
    this._responseInterceptor();
  }

  private _requestInterceptor = () => {
    this.instance.interceptors.request.use(
        this._handleRequest
    );
  };

  private _responseInterceptor = () => {
    this.instance.interceptors.response.use(
        this._handleResponse,
        this._handleResponseError,
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    // let user = JSON.parse(<string>localStorage.getItem('user'));
    //
    // if (user && user.username) {
    //   config.headers['X-LK-USERNAME'] = user.username;
    // }

    return config;
  };

  private _handleResponse = (response: AxiosResponse) => {
    return response.data;
  };

  protected _handleResponseError = (error: any) => {

    if (error.isAxiosError && !error.response) {

      // store.dispatch("saveInformErrorPopup", {isNetworkError: true, message: InformErrorPopupMessage.NoNetwork});
      return error;
    }

    if (error.isAxiosError && error.response.data.message === "Server error") {

      // store.dispatch("saveUnknownErrorPopup", {isError: true, fieldErrors: {unknownError: error.response.data.message}})
      return Promise.reject(error);
    }

    const {
      config,
      response: {status, data}
    } = error;

    // if ([401, 403].includes(status)) {
    //   store.dispatch("logout");
    // }

    return Promise.reject(error);
  };

  public async get<T>(url: string): Promise<any> {
    return execRequest(async () => {
      return this.instance.get<T>(url);
    });
  }

  public async getWithParams<T>(url: string, params: any): Promise<any> {
    return execRequest(async () => {
      return this.instance.get<T>(url, {params});
    });
  }

  public async post<T>(url: string, jsonParams: object): Promise<any> {
    return execRequest(async () => {
      return await this.instance.post<T>(url, jsonParams)
    });
  }

  public async delete<T>(url: string): Promise<any> {
    return execRequest(async () => {
      return await this.instance.delete<T>(url);
    });
  }

  public async put<T>(url: string, jsonParams: any): Promise<any> {
    return execRequest(async () => {
      return await this.instance.put<T>(url, jsonParams);
    })
  }
}

export default {
  http: new HttpClient('/api/rider'),
  defaultHttpClient: new HttpClient('/')
};
