// import {HttpClient} from './http-client';

import httpClient from './http-client';

class BackendApi {

  // protected readonly userHttpClient: HttpClient = new HttpClient('/rider');
  // protected readonly defaultHttpClient: HttpClient = new HttpClient('');

  public fetchTripList = () => httpClient.http.get('tripList');

  public fetchTripDetailsAndPackets = (id: number) => httpClient.http.get(`tripDetailsAndPackets/${id}`);

  public fetchTripDetails = (id: number) => httpClient.http.get(`tripDetails/${id}`);

  public fetchUserTripHistory = () => httpClient.http.get(`userTripHistory`);

  public sendTripRecords = (object: {}) => httpClient.http.post(`createTripRecords`, object);

  public userData = () => httpClient.http.get('userData');

  public userBonuses = () => httpClient.http.get('bonuses');

  public logout = () => httpClient.defaultHttpClient.get('/api/logout/index');

}

export default new BackendApi();
