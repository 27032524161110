import {reactive} from 'vue';
import backendApi from "@/backend-api";
import UserTripHistory from "@/models/UserTripHistory";
import UserTripHistoryItem from "@/models/UserTripHistoryItem";

const tripHistoryState = reactive({
    list: {} as UserTripHistoryItem[],
})


const updateData = (data: UserTripHistory) => {
    if (Object.keys(data).length == 0) {
        return
    }

    tripHistoryState.list = data?.list
}

const fetchUserTripHistory = async () => {
    updateData({} as UserTripHistory)

    const response: UserTripHistory = await backendApi.fetchUserTripHistory()

    updateData(response)
}

export default {
    tripHistoryState,
    fetchUserTripHistory
}
