
import {defineComponent, PropType} from 'vue'
import authStore from "@/store/auth";
import {auth} from "@/scripts/authByVk";
import TripRecord from "@/models/trip/TripRecord";
import ConfirmTripRecordItem
  from "@/views/TripDetails/components/modals/ConfirmTripRecordModal/components/ConfirmTripRecordItem.vue";
import ResponseMessage from "@/models/trip/ResponseMessage";
import TripDetailsAndPackets from "@/models/trip/TripDetailsAndPackets";

export default defineComponent({
  name: 'ConfirmTripRecordModal',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    bonuses: {
      type: Number,
      required: true
    },
    tripRecords: {
      type: [] as PropType<TripRecord[]>,
      required: true
    },
    trip: {
      type: Object as PropType<TripDetailsAndPackets>,
      required: true
    }
  },
  components: {
    ConfirmTripRecordItem
  },
  emits: {
    onCloseModal: null,
    onSendTripRecords: null,
    onPayWithTripBonuses: null
  },
  setup(props, {emit}) {
    const {
      authState,
      fetchUserData,
    } = authStore

    const payWithTripBonuses = (val: number) => {
      const bonuses = Math.min(
          authState.userData.bonuses,
          val,
          props.totalPrice
      )

      emit("onPayWithTripBonuses", bonuses)
    }

    const closeModal = () => {
      emit("onCloseModal")
    }
    const createTripRecords = async () => {
      emit("onSendTripRecords")
    }


    const authByVk = async () => {
      await auth(
          authState.userData.vkLink,
          fetchUserData
      )

      if (authState.userData.username) {
        closeModal()
        alert("done!")
      }
    }

    return {
      closeModal,
      authByVk,
      authState,
      createTripRecords,
      payWithTripBonuses
    }
  }
})
