<template>
  <header id="header" class="header-tops">
    <div class="container" :class="{'navigation-background' : !showLoginInfo}">

      <h1>
        <router-link to="/">ZAVBUS</router-link>
      </h1>

      <h2>
        Организация туров в горнолыжные центры
      </h2>

      <nav class="nav-menu d-none d-lg-block">
        <NavigationLinks/>
      </nav>

      <slot/>

      <LoginInfo
          v-if="showLoginInfo"
          class="login-info d-none d-lg-block"
      />

      <Sidebar>
        <template v-slot:login-info>
          <LoginInfo />
        </template>
        <NavigationLinks/>
      </Sidebar>

      <div class="social-links">
        <a href="https://www.instagram.com/zavbus/" class="instagram">
          <i class="icofont-instagram"/>
        </a>

        <a href="https://vk.com/zavbus/" class="instagram">
          <i class="bx bxl-vk social-links__icon"/>
        </a>
      </div>

    </div>
  </header>
</template>

<script lang="ts">
  import {defineComponent} from "vue"
  import NavigationLinks from '@/components/NavigationLinks.vue';
  import LoginInfo from '@/components/LoginInfo.vue';
  import Sidebar from '@/components/menu/Sidebar.vue';

  export default defineComponent({
    name: 'Header',
    props: {
      showLoginInfo: Boolean
    },
    components: {
      LoginInfo,
      Sidebar,
      NavigationLinks
    }
  })
</script>

<style>
  .container {
    text-align: left;
  }

  #header.header-top h1 a {
    color: #fff;
  }
  .login-info{
    margin-left: 50px;
    display: inline-block;
  }

  .navigation-background {
    background: linear-gradient(to right, rgba(204, 204, 204, 0.74), rgba(0, 255, 255, 0))!important;
    padding: 30px
  }
</style>
