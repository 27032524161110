<template>

  <div>
    <vue-final-modal :model-value="showModal" classes="modal-container" content-class="modal-content">
      <i
          @click="closeModal"
          class="bx bx-x modal__close"
          style="color: white; font-size: 38px; cursor: pointer"
      />

      <span class="modal__title">Отмена записи на выезд</span>

      <div class="modal__content">
        <div>
          По техническим причинам автоматическая отмена записи на выезд пока недоступна
        </div>

        <div>
           Для отмены свяжитесь с нами
          <a href="tel:+73517507287" class="footer-phone">
            <i class="fa fa-phone" aria-hidden="true"></i> +7 (351) 750-72-87
          </a>
        </div>

      </div>
      <div class="modal__action">
        <button class="btn btn-auth" @click="closeModal">
          Хорошо
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'CancelTripRecordWarning',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    //
    // tripRecord: {
    //   type: [] as PropType<TripRecord>,
    //   required: true
    // }
  },
  emits: {
    onCloseModal: null
  },
  setup(props, {emit}) {
    const closeModal = () => {
      emit("onCloseModal")
    }

    return {
      closeModal
    }
  }
})
</script>

<style scoped>
.btn-auth {
  color: #000;
  background: #18d26e;
}

.btn-vk-auth {
  color: #fff;
  background: #2a5885;
}


::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 70%;
  width: 90%;
  max-width: 700px;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}

.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

  <style scoped>
  div::v-deep .modal-content {
    z-index: 9999999;
    border-color: #2d3748;
    background: rgb(0, 0, 0);
  }

.bonus-info {
  padding: 1rem;
  background: rgba(204, 204, 204, 0.1);
}

</style>
