<template>
  <section id="trip" class="trip section-show" style="height: auto!important;">
    <div class="container">

      <Loader v-if="state?.loading"/>
      <div class="row mt-2" v-else-if="state.trip?.details && state.trip?.details?.id === tripId">

        <div class="col-lg-5">
          <div class="d-block d-lg-none trip-record__status-message" v-if="state.trip?.inPrepaidList">

            <i
                class="bx bxs-info-circle message__error-icon"
            />

            <span>Ваша заявка в резерве. Для подтверждения записи на выезд необходима предоплата</span>
          </div>

          <div class="trip-block">
            <keep-alive>
              <img
                  :src="getImageUrl(tripId)"
                  class="img-fluid" alt=""
              >
            </keep-alive>
          </div>

          <TripInfoComponent :trip="state.trip"/>
        </div>

        <div class="col-lg-7 trip-info">
          <div v-if="state.trip?.details?.inPast" class="auth-warning">
            <i class="bx bx-error-circle"/>
            Выезд закрыт или завершен
          </div>
          <div v-else>
            <CancelTripRecordWarning
                :showModal="showCancelTripRecordModal"
                @onCloseModal="showCancelTripRecordModal = false"
            />

            <div v-if="!state.trip?.details?.openedCheckIn" class="auth-warning">
              <i class="bx bx-error-circle"/>
              Регистрация на выезд закрыта
            </div>

            <!-- TODO в отдельный компонент и обработкой по состоянию  -->
            <div class="d-none d-lg-block d-xl-block trip-record__status-message" v-if="state.trip?.inPrepaidList">

              <i
                  class="bx bxs-info-circle message__error-icon"
              />

              <span>Ваша заявка в резерве. Для подтверждения записи на выезд необходима предоплата</span>
            </div>

            <MessageComponent
                v-if="state.messageInfo.text"
                :message="state.messageInfo"
                :is-info="true"
                @onClearMessage="clearMessage"
            />
          </div>

          <TripRecordComponent
              v-for="(tripRecord, index) in state.tripRecords"
              :key="tripRecord.id"
              v-model:trip-record="state.tripRecords[index]"
              :tripRecordErrors="tripRecordErrors"
              :trip-record-index="tripRecord.id"
              :trip-record-errors="getTriRecordErrors(tripRecord)"
              :is-logged-in="authState.userData.username != null"
              :show-details="currentRiderIndex === tripRecord.id && tripRecord.id < 0"
              @onRemoveRider="tryRemoveTripRecord(tripRecord)"
              @onSetCurrentRiderIndex="setCurrentRiderIndex(tripRecord.id)"
              @onConfirmTripRecord="setCurrentRiderIndex(0)"
          />

          <div class="text-right ">
            <span class="trip-records__result">
              Полная стоимость {{ getTotalPrice() }} ₽
            </span>
            <br>
            <span v-if="getFullPrepaidSum() > 0" class="trip-records__for-payment-sum">
              К оплате {{ getTotalPrice() - getFullPrepaidSum()}} ₽
            </span>
          </div>

          <div v-if="!authState.userData.username">
            <div class="auth-warning">
              <i class="bx bx-error-circle"/>
              Для регистрации на выезд необходима авторизация через
              <span class="auth-warning-vk" @click="authByVk">VK</span>
            </div>

            <div class="text-right">
              <button class="btn trip-record-btn" @click="authByVk">
                Авторизоваться
              </button>
            </div>
          </div>

          <div v-else>
            <ConfirmTripRecordModal
                v-if="state.trip?.details?.openedCheckIn"
                :loading="state.loading"
                :show-modal="showModal"
                :total-price="getTotalPrice()"
                :trip-records="state.tripRecords"
                :trip="state.trip"
                :bonuses="state.bonuses"
                @onSendTripRecords="trySendTripRecords"
                @onPayWithTripBonuses="payWithTripBonuses"
                @onCloseModal="closeConfirmTripRecordModal"
            >
              <template v-slot:message>
                <MessageComponent
                    v-if="state.message.text"
                    :message="state.message"
                    :is-info="false"
                    @onClearMessage="clearMessage"
                />
              </template>

            </ConfirmTripRecordModal>

            <div class="auth-warning" v-if="tripRecordErrors.length > 0">
              <i class="bx bx-error-circle"/>
              Заполните все необходимые данные
            </div>

            <div class="text-right"
                 v-if="state.trip?.details?.openedCheckIn"
            >

              <button
                  class="btn trip-record-btn"
                  @click="newTripRecordAndSetCurrent('', '', '')"
              >
                Добавить друга
              </button>

              <button
                  class="btn trip-record-btn"
                  @click="createTripRecords"
              >
                Записаться
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        Пустота...
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useRoute} from 'vue-router'
import tripDetailsStore from "@/store/trip-details";
import authStore from "@/store/auth";
import TripInfoComponent from "@/views/TripDetails/components/TripInfoComponent.vue"
import TripRecordComponent from "@/views/TripDetails/components/TripRecordComponent.vue"
import ConfirmTripRecordModal
  from "@/views/TripDetails/components/modals/ConfirmTripRecordModal/ConfirmTripRecordModal.vue"
import CancelTripRecordWarning from "@/views/TripDetails/components/modals/CancelTripRecordWarning.vue"
import MessageComponent from "@/views/TripDetails/components/MessageComponent.vue"
import TripRecord from "@/models/trip/TripRecord";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: 'TripDetails',
  components: {
    ConfirmTripRecordModal,
    CancelTripRecordWarning,
    TripInfoComponent,
    TripRecordComponent,
    MessageComponent,
    Loader
  },
  setup: function () {
    const {
      state,
      fetchTripDetailsAndPackets,
      editFirstTripRecordByUserData,
      getTotalPrice,
      getFullPrepaidSum,
      newTripRecord,
      resetSelectedPacket,
      removeTripRecord,
      sendTripRecords,
      payWithTripBonuses,
      clearMessage
    } = tripDetailsStore

    const {authState, authByVk, fetchUserData} = authStore

    const checkData = ref(false)
    const showModal = ref(false)
    const showCancelTripRecordModal = ref(false)
    const tripRecordErrors = ref([] as TripRecordErrors[])

    const currentRiderIndex = ref(-1)
    const currentTripRecord = ref() //показывает форму для выбранного райдера

    const tripId = ref(0)

    // const tripId = ref(+computed(() => useRoute().params.id).value);

    const setCurrentRiderIndex = (index: number) => {
      currentRiderIndex.value = index
    }

    const setCurrentTripRecord = (tripRecord: TripRecord) => {
      currentTripRecord.value = tripRecord
    }

    const newTripRecordAndSetCurrent = (
        name: string,
        surname: string,
        phone: string
    ) => {
      const newRecord = newTripRecord(name, surname, phone)

      setCurrentRiderIndex(newRecord.id)
    }

    const getTriRecordErrors = (tripRecord: TripRecord): TripRecordErrors | undefined => {
      return tripRecordErrors.value.find(err => err.tripRecord === tripRecord)
    }

    const isCorrectData = computed(() => {
      return state.tripRecords.filter(
          rp => rp.name == "" || rp.surname == "" || rp.selectedPacket.id == null
      ).length == 0
    })

    const createTripRecords = async () => {
      await authStore.fetchUserData()
      tripRecordErrors.value = [];

      state.tripRecords.forEach((it: TripRecord) => {
        const re: TripRecordErrors = TripRecordErrors.validate(it)
        if (re !== undefined && re.hasErrors) {
          tripRecordErrors.value.push(re)
        }
      })

      if (tripRecordErrors.value.length === 0) {
        showModal.value = true
      }
    }

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id

      await fetchTripDetailsAndPackets(tripId.value)

      if (state.tripRecords.length === 0) {
        newTripRecordAndSetCurrent(
            authState.userData.name,
            authState.userData.surname,
            authState.userData.phone
        )
      } else {
        resetSelectedPacket()
      }
    })

    watch(() => authState.userData.username, async (newValues, prevValues) => {
      if (newValues != null) {
        await fetchTripDetailsAndPackets(tripId.value)
        editFirstTripRecordByUserData(authState.userData)
      }
    })

    const tryRemoveTripRecord = (tripRecord: TripRecord) => {
      if (tripRecord.id > 0) {
        showCancelTripRecordModal.value = true
      } else {
        removeTripRecord(tripRecord)
      }
    }

    const trySendTripRecords = async () => {
      const resp = await sendTripRecords()

      if (resp.success) {
        showModal.value = false
        clearMessage()
      }
    }

    const closeConfirmTripRecordModal = () => {
      showModal.value = false
      clearMessage()
    }

    const getImageUrl = (id: number) => {
      return "https://zavbus.ru/api/image/displayTripImage/" + id
    }

    return {
      getImageUrl,
      tripRecordErrors,
      getTriRecordErrors,
      checkData,
      showModal,
      showCancelTripRecordModal,
      currentRiderIndex,
      setCurrentRiderIndex,
      currentTripRecord,
      isCorrectData,
      setCurrentTripRecord,
      state,
      tripId,
      getTotalPrice,
      getFullPrepaidSum,
      newTripRecordAndSetCurrent,
      tryRemoveTripRecord,
      trySendTripRecords,
      createTripRecords,
      authState,
      authByVk,
      payWithTripBonuses,
      clearMessage,
      closeConfirmTripRecordModal
    }
  },
})
</script>

<style scoped>
.trip-record-btn {
  color: #000;
  background: #18d26e;
  margin-left: 10px;
}

.auth-warning {
  border-radius: 0.25rem;
  padding: 20px;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 2rem;
}

.auth-warning i {
  color: #0d6efd;
}

.auth-warning-vk {
  cursor: pointer;
}

.trip-block {
  margin-bottom: 20px;
}

.trip-records__result {
  font-size: 1.5rem;
}

.trip-records__for-payment-sum {
  font-size: 1.5rem;
  font-weight: 500;
}

.message__error-icon {
  margin-right: 5px;
  color: #d31515
}

.trip-record__status-message {
  border-radius: 0.25rem;
  padding: 20px;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 2rem;
}

</style>
