
import {defineComponent, onBeforeMount, ref} from "vue";
import {useRoute} from 'vue-router'
import tripRecordsStore from "@/store/trip-records";

export default defineComponent({
  name: 'TripAllDetailsPage',
  components: {},
  setup: function () {
    const {
      tripDetailsState,
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
    })

    return {
      tripId,
      tripDetailsState
    }
  },
})
