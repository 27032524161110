<template>

  <div style="text-align: left;">
    <h5>
      <i class="bx bx-info-circle"></i>
      Включено в программу "{{ selectedPacket.name }}"
    </h5>
    <ul>
      <li
          v-for="service in selectedPacket.services.filter(it => it.mustHave)"
          :key="service.id"
      >
        {{ service.name }}
      </li>
    </ul>
  </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import TripPacket from "@/models/trip/TripPacket";

export default defineComponent({
  name: 'TripPacketComponent',
  props: {

    selectedPacket: {
      type: Object as PropType<TripPacket>,
      required: false
    }
  }
})
</script>

<style scoped>


</style>
