<template>

  <div class="trip-block">
    <h3>Информация о туре</h3>
    <ul>
      <li>
        <strong>
          Направление
        </strong>:
        {{ trip?.details.name }}
      </li>
      <li>
        <strong>
          Дата
        </strong>:
        {{ trip?.details.tripDates }}
      </li>
      <li>
        <strong>
          Участники
        </strong>:
        <router-link :to="{name: 'riders',  params: { id: trip?.details?.id }}">
          {{ trip?.details.members }} / {{ trip?.details.maxMembers }}
        </router-link>
      </li>
      <li>
        <strong>
          Подробнее о выезде
        </strong>:
        <router-link :to="{name: 'info',  params: { id: trip?.details?.id }}">
          тут
        </router-link>
      </li>
      <!--      <li>-->
      <!--        <strong>-->
      <!--          Ссылка на альбом-->
      <!--        </strong>:-->
      <!--        <a href="https://vk.com/zavbus">vk.com/zavbus</a>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <strong>-->
      <!--          Информация о бонусах-->
      <!--        </strong>:-->
      <!--        <a href="https://vk.com/zavbus">vk.com/zavbus</a>-->
      <!--      </li>-->
    </ul>

    <div v-if="trip?.details?.timing">
      <h3>Тайминг</h3>
      <div v-html="trip?.details?.timing" class="trip-block__timing"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import TripDetailsAndPackets from "@/models/trip/TripDetailsAndPackets";

export default defineComponent({
  name: 'TripInfoComponent',
  props: {
    trip: {
      type: Object as PropType<TripDetailsAndPackets>,
      required: true
    }
  }
})
</script>

<style scoped>

/*.trip-block__timing {*/
/*  padding-left: 1rem;*/
/*  font-family: 'Raleway', sans-serif;*/
/*  white-space: pre-wrap;*/
/*  font-size: 1rem;*/
/*  font-weight: 400;*/
/*  line-height: 1.5;*/
/*}*/
</style>
