<template>

  <div>
    <vue-final-modal :model-value="showModal" classes="modal-container" content-class="modal-content">
      <i
          @click="closeModal"
          class="bx bx-x modal__close"
          style="color: white; font-size: 38px; cursor: pointer"
      />

      <span class="modal__title">
        Заявка на выезд
        {{ authState.userData.inPrepaidList ? '(в резерв)': ''}}
      </span>

      <div class="modal__bonuses-info" v-if="authState.userData.inPrepaidList">
        <span>
          Ваша заявка попадет в резерв. Для подтверждения заявки на выезд необходима предоплата. Реквизиты будут далее
        </span>
      </div>

      <slot name="message"></slot>
      <div class="modal__content">

        <ConfirmTripRecordItem
            v-for="tripRecord in tripRecords"
            :key="tripRecord.id"
            :trip-record="tripRecord"
            :selected-services="tripRecord.selectedServices.filter(it => !it.mustHave).map(it => it.name).join(', ')"
        />

      </div>
      <div class="modal__action">
          <div class="modal__bonuses-info" v-if="trip.details.displayedBonusesByRegFromLk">
            <span>Авторизация через соц.сети +{{ trip.details.displayedBonusesByRegFromLk }}</span>
          </div>

        <div class="modal__bonuses" v-if="tripRecords.filter(it => it.id < 0 ).length > 0">
          <span>Оплатить бонусами: </span>
          <input type="number" @input="payWithTripBonuses($event.target.value)" value="0"/>
          <span> доступно {{ authState.userData.displayedBonuses }}</span>
        </div>

        <div style="text-align: right">
          <h3>
            <span v-if="bonuses > 0">
              Итого c учётом бонусов: {{ totalPrice - bonuses }} ₽
            </span>
            <span v-else>
              Итого: {{ totalPrice }} ₽
            </span>
          </h3>
        </div>

        <div style="text-align: center">
          <button
              v-if="!authState.userData.username"
              class="btn btn-vk-auth"
              @click="authByVk"
          >
            Авторизация через VK
          </button>

          <button
              v-else-if="tripRecords.filter(it => it.id < 0 ).length > 0"
              class="btn btn-auth"
              :disabled="loading"
              @click="createTripRecords"
          >
            {{
              authState.userData.inPrepaidList
                  ? 'Отправить заявку на выезд'
                  : 'Записаться'
            }}
          </button>

          <div v-else>
            <div>Все участники записаны на выезд</div>
            <div>
              <button class="btn btn-auth" @click="closeModal">
                Хорошо
              </button>
            </div>
          </div>
        </div>

      </div>
    </vue-final-modal>

  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import authStore from "@/store/auth";
import {auth} from "@/scripts/authByVk";
import TripRecord from "@/models/trip/TripRecord";
import ConfirmTripRecordItem
  from "@/views/TripDetails/components/modals/ConfirmTripRecordModal/components/ConfirmTripRecordItem.vue";
import ResponseMessage from "@/models/trip/ResponseMessage";
import TripDetailsAndPackets from "@/models/trip/TripDetailsAndPackets";

export default defineComponent({
  name: 'ConfirmTripRecordModal',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    bonuses: {
      type: Number,
      required: true
    },
    tripRecords: {
      type: [] as PropType<TripRecord[]>,
      required: true
    },
    trip: {
      type: Object as PropType<TripDetailsAndPackets>,
      required: true
    }
  },
  components: {
    ConfirmTripRecordItem
  },
  emits: {
    onCloseModal: null,
    onSendTripRecords: null,
    onPayWithTripBonuses: null
  },
  setup(props, {emit}) {
    const {
      authState,
      fetchUserData,
    } = authStore

    const payWithTripBonuses = (val: number) => {
      const bonuses = Math.min(
          authState.userData.bonuses,
          val,
          props.totalPrice
      )

      emit("onPayWithTripBonuses", bonuses)
    }

    const closeModal = () => {
      emit("onCloseModal")
    }
    const createTripRecords = async () => {
      emit("onSendTripRecords")
    }


    const authByVk = async () => {
      await auth(
          authState.userData.vkLink,
          fetchUserData
      )

      if (authState.userData.username) {
        closeModal()
        alert("done!")
      }
    }

    return {
      closeModal,
      authByVk,
      authState,
      createTripRecords,
      payWithTripBonuses
    }
  }
})
</script>

<style scoped>
.btn-auth {
  color: #000;
  background: #18d26e;
}

.btn-vk-auth {
  color: #fff;
  background: #2a5885;
}


::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 70%;
  width: 90%;
  max-width: 700px;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 1rem;
}

.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}

.modal__action {
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
div::v-deep .modal-content {
  z-index: 9999999;
  border-color: #2d3748;
  background: rgb(0, 0, 0);
}

.modal__bonuses-info {
  padding: 1rem;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 1rem;
}

.modal__bonuses input {
  border: 0px;
  background: rgba(204, 204, 204, 0.1);
  padding: 5px 5px;
  color: #18d26e;
}

.modal__bonuses input:focus {
  border: 0px;
  background: #18d26e;
  box-shadow: 0 0 3px #719ece;
  color: #000;
  outline: none;
}

.modal__bonuses span {
  font-weight: 700
}

.modal__bonuses {
  text-align: right;
  margin-bottom: 1rem;
}

.modal__bonuses span {
  white-space: nowrap
}

</style>
