
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'CancelTripRecordWarning',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    //
    // tripRecord: {
    //   type: [] as PropType<TripRecord>,
    //   required: true
    // }
  },
  emits: {
    onCloseModal: null
  },
  setup(props, {emit}) {
    const closeModal = () => {
      emit("onCloseModal")
    }

    return {
      closeModal
    }
  }
})
