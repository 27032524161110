import TripRecord from "@/models/trip/TripRecord";


export default class TripRecordErrors {
  constructor(
      public tripRecord: TripRecord,
      public errorFields: (keyof TripRecord)[],
      public hasErrors: boolean
  ) {
  }

  public static validate(riderAndPacket: TripRecord): TripRecordErrors {
    const err: (keyof TripRecord)[] = [];

    if (typeof riderAndPacket.name == 'undefined' || !riderAndPacket.name) {
      err.push("name")
    }

    if (typeof riderAndPacket.surname == 'undefined' || !riderAndPacket.surname) {
      err.push("surname")
    }

    if (typeof riderAndPacket.selectedPacket == 'undefined' || !riderAndPacket.selectedPacket.id) {
      err.push("selectedPacket")
    }

    return new TripRecordErrors(riderAndPacket, err, err.length > 0)
  }

}
