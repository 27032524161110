
import {defineComponent, PropType} from 'vue'
import TripPacket from "@/models/trip/TripPacket";

export default defineComponent({
  name: 'TripPacketComponent',
  props: {

    selectedPacket: {
      type: Object as PropType<TripPacket>,
      required: false
    }
  }
})
