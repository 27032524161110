
  import {computed} from "vue";
  import store from "@/store";

  export default {
    name: "Sidebar",
    data: () => ({
    }),
    methods: {
      close() {
        store.dispatch("initBurgerMenu")
      }
    },
    setup() {
      const isNavOpen = computed(() => store.state.isNavOpen)

      const initBurgerMenu = () => {
        store.dispatch("initBurgerMenu")
      }

      return {
        isNavOpen,
        initBurgerMenu
      }
    }
  }
