<template>
  <div class="trip-record-item ">
    <div>
      <i
          v-if="tripRecord.id > 0"
          class="bx bxs-check-circle"
          style="color: #18d26e; margin-right: 5px"
      />
      <span v-if="tripRecord.tripRecordRiderName">{{ tripRecord.tripRecordRiderName }}</span>
      <span v-else>{{ tripRecord.name }} {{ tripRecord.surname }}</span>
      <span v-if="tripRecord.phone">&nbsp;<i class="bx bx-phone"/> {{ tripRecord.phone }} </span>
    </div>

    <div>Программа: <span style="color: #18d26e">{{ tripRecord.selectedPacket.name }}</span></div>

    <div v-if="selectedServices">Дополнительно: {{ selectedServices }}</div>

  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import TripRecord from "@/models/trip/TripRecord";

export default defineComponent({
  name: 'ConfirmTripRecordItem',
  props: {
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: true
    },
    selectedServices: {
      type: String,
      required: false
    }
  }
})
</script>

<style scoped>
.trip-record-item {
  padding: 1rem;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 1rem;
}

</style>
