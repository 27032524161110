
import {defineComponent} from "vue";
import ServiceItem from "@/views/ZavbusServiceList/components/ServiceItem.vue";
import zavbusServicesStore from "@/store/zavbus-services";

export default defineComponent({
  name: 'Services',
  components: {
    ServiceItem
  },
  setup: function () {
    const {
      zavbusServicesState
    } = zavbusServicesStore

    return {
      zavbusServicesState
    }
  }
})
