import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import TripList from '@/views/TripList.vue'
import ContactsPage from '@/views/ContactsPage.vue'
import  TripHistoryPage from '@/views/TripHistory/TripHistoryPage.vue'
import ZavbusServiceListPage from '@/views/ZavbusServiceList/ZavbusServiceListPage.vue'
import ZavbusServicePage from '@/views/ZavbusService/ZavbusServicePage.vue'
import TripDetailsView from '@/views/TripDetails/TripDetailsView.vue'
import TripRidersPage from '@/views/TripRiders/TripRidersPage.vue'
import TripAllDetailsPage from '@/views/TripAllDetails/TripAllDetailsPage.vue'
import TripPageLayout from "@/views/TripPageLayout.vue";
import RiderBonusesPage from "@/views/RiderBonuses/RiderBonusesPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'firstPage',
    meta: {layout: 'ContentLayout'},
    component: TripList
  },
  {
    path: '/trips',
    name: 'tripList',
    meta: {layout: 'ContentLayout'},
    component: TripList
  },
  {
    path: '/contacts',
    name: 'contacts',
    meta: {layout: 'ContentLayout'},
    component: ContactsPage
  },
    {
        path: '/tripHistory',
        name: 'tripHistory',
        meta: {layout: 'ContentLayout'},
        component: TripHistoryPage
    },
  {
    path: '/services',
    name: 'services',
    meta: {layout: 'ContentLayout'},
    component: ZavbusServiceListPage
  },
  {
    path: '/service/:code',
    name: 'service',
    meta: {layout: 'ContentLayout'},
    component: ZavbusServicePage
  },
  {
    path: '/tripdetails/:id',
    name: 'tripDetails',
    meta: {layout: 'ContentLayout'},
    component: TripDetailsView
  },
  {
    path: '/trip/:id',
    name: 'trip',
    meta: {layout: 'ContentLayout'},
    component: TripPageLayout,
    children: [
      {
        path: 'riders',
        name: 'riders',
        component: TripRidersPage
      },
      {
        path: 'info',
        name: 'info',
        component: TripAllDetailsPage
      }
    ]
  },
  {
    path: '/rider/bonuses',
    name: 'riderbonuses',
    meta: {layout: 'ContentLayout'},
    component: RiderBonusesPage,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
