<template>

  <div class="rider-packet-result-row">
    <table style="width: 100%" @click="$emit('onSetCurrentRiderIndex')">
      <tr>
        <td class="text-left">
          <i class="bx bx-error-circle trip-record-error__icon" v-if="tripRecordErrors?.hasErrors && !showDetails"/>
          <h4 v-if="tripRecord.name || tripRecord.surname">
            <span v-if="tripRecord.id > 0">
              <i
                 class="bx bxs-check-circle trip-record__confirmed-record-icon"
              />
            </span>

            <span v-if="tripRecord.tripRecordRiderName">
              {{ tripRecord.tripRecordRiderName }}
            </span>
            <span v-else>
              {{ tripRecord.name }} {{ tripRecord.surname }}
            </span>

          </h4>
          <h4 v-else-if="!tripRecord.selectedPacket.id">
            Выбор программы тура
          </h4>
          <h4 v-else-if="!tripDetails?.openedCheckIn">

          </h4>
          <h4 v-else>
            Укажите информацию об участнике
          </h4>
        </td>
        <td>
          <i
              class="bx bx-trash-alt"
              @click.stop.prevent="emitOnRemoveRider(tripRecord)"
          />
        </td>
      </tr>
      <tr>
        <td class="text-left text-green">
          {{ displaySelectedPacketAndServices(tripRecord) }}
          <FieldErrorIconComponent
              :trip-record-errors="tripRecordErrors"
              :trip-record-field="'selectedPacket'"
          />
        </td>
        <td>
          <div class="trip-record__full-price" v-html="getDisplayedTotalRiderPrice(tripRecord)"/>
          <div v-text="getDiscountInfo(tripRecord)" style="font-size: 12px;"/>
          <div class="text-green" v-if="tripRecord.prepaidSum > 0" style="font-size: 12px;">предоплата
            {{ tripRecord.prepaidSum }} ₽
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-left ">
          <div v-for="(orderedKit, index) in tripRecord.orderedKit" :key="index">
            {{ orderedKit.displayedType }}: <span class="text-green"
                                                  style="white-space: nowrap;">{{ orderedKit.displayedName }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import TripRecord from "@/models/trip/TripRecord";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import FieldErrorIconComponent from "@/views/TripDetails/components/FieldErrorIconComponent.vue";
import TripDetails from "@/models/TripDetails";

export default defineComponent({
  name: 'TripRecordTitleComponent',
  props: {
    tripDetails: {
      type: Object as PropType<TripDetails>,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    },
    tripRecordIndex: {
      type: Number,
      required: true
    },
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: true
    },
    tripRecordErrors: {
      type: Object as PropType<TripRecordErrors>,
      required: false
    },
  },
  components: {
    FieldErrorIconComponent
  },
  emits: ['onRemoveRider'],
  setup(props, {emit}) {
    const displaySelectedPacketAndServices = (tripRecord: TripRecord) => {
      if (props.showDetails) {
        return ""
      }

      if (!tripRecord.selectedPacket.id) {
        return "Выберите программу"
      }

      const servicesNames = tripRecord.selectedServices
          ?.filter(it => !it.mustHave)
          .map(it => it.name)
          .join(", ")

      return tripRecord.selectedPacket.name + (servicesNames ? " + " + servicesNames : "")
    }

    const getTotalRiderPrice = (tripRecord: TripRecord) => {
      if (tripRecord.id > 0) {
        return tripRecord.fullPrice
      }

      if (!tripRecord.selectedPacket.id) {
        return 0;
      }

      let sum = 0;
      tripRecord.selectedServices
          ?.filter(s => !s.mustHave)
          ?.forEach(a => sum += a.price)

      return tripRecord.selectedPacket.price + sum;
    }

    const getForPayment = (tripRecord: TripRecord) => {
      return getTotalRiderPrice(tripRecord) - tripRecord.discountSum - tripRecord.paidBonuses
    }

    const getDisplayedTotalRiderPrice = (tripRecord: TripRecord) => {
      const totalRiderPrice = getTotalRiderPrice(tripRecord)
      const forPayment = getForPayment(tripRecord)

      let priceInfo = `${forPayment} ₽`
      if (totalRiderPrice != forPayment) {
        priceInfo = `<s> ${getTotalRiderPrice(tripRecord)}</s>&nbsp` + priceInfo
      }

      return priceInfo
    }

    const getDiscountInfo = (tripRecord: TripRecord) => {
      const result: string[] = [];

      if (tripRecord.paidBonuses) {
        result.push(tripRecord.displayedPaidBonuses)
      }

      if (tripRecord.discountSum) {
        result.push(`скидка ${tripRecord.discountSum} ₽`)
      }

      return result.join(", ")
    }

    const emitOnRemoveRider = (tripRecord: TripRecord) => {
      emit("onRemoveRider", tripRecord)
    }

    return {
      displaySelectedPacketAndServices,
      getTotalRiderPrice,
      getDisplayedTotalRiderPrice,
      getDiscountInfo,
      emitOnRemoveRider
    }
  }
})
</script>

<style scoped>
.rider-packet-result-row {
  cursor: pointer;
  margin-bottom: 5px;
}

.text-green {
  color: #18d26e;
}

.trip-record-error__icon {
  color: #d31515
}

.trip-record__full-price {
  /*font-weight: 700;*/
  font-size: 1.5rem;
  white-space: nowrap
}

.trip-record__confirmed-record-icon {
  color: #18d26e;
  margin-right: 5px;
}

.trip-record__prepaid-record-icon {
  color: #d31515;
  margin-right: 5px;
}


</style>
