
import {defineComponent, onBeforeMount} from "vue";
import userTripHistoryStore from "@/store/user-trip-history.ts";
import {useRoute} from "vue-router";
import authStore from "@/store/auth";

export default defineComponent({
  name: 'TripHistoryPage',
  components: {},
  setup: function () {
    const {
      tripHistoryState,
      fetchUserTripHistory
    } = userTripHistoryStore

    const {
      authState
    } = authStore

    onBeforeMount(async () => {
      await fetchUserTripHistory()
    })

    return {
        authState,
      tripHistoryState
    }
  },
})
