
import {defineComponent} from "vue"
import authStore from "@/store/auth";

export default defineComponent({
  name: 'NavigationLinks',
  setup() {
    const {
      authState
    } = authStore

    return {
      authState
    }
  }
})
