<template>
  <div>
    <div
        class="card h-100 service-in-the-trip"
        :class="{'service-in-the-trip-selected' : isSelectedService}"
    >

        {{ service.name }}

      <span class="service-in-the-trip__price">
        {{ service.price }} ₽
      </span>
    </div>
  </div>

</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue'
import ServiceInTrip from "@/models/trip/ServiceInTrip";

export default defineComponent({
  name: 'ServiceInTripComponent',
  props: {
    service: {
      type: Object as PropType<ServiceInTrip>,
      required: true
    },
    isSelectedService: {
      type: Boolean,
      required: true
    }
  }
})
</script>

<style scoped>
.service-in-the-trip {
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.44);
  padding: 15px 15px;
  /*color: #18d26e;*/
}

.service-in-the-trip span {
  color: #18d26e;
}

.service-in-the-trip-selected, .service-in-the-trip-selected span {
  background: #18d26e;
  color: #000;
}

.h-100 {
  width: 100%;
  height: 100%;
}


</style>
