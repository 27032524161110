<template>

  <div>
    <h2>
      <router-link :to="{name: 'tripDetails', params: { id: tripDetailsState?.details?.id }}">
        {{ tripDetailsState?.details?.name }} - {{ tripDetailsState?.details?.tripDates }}
      </router-link>
    </h2>
    <hr>

    <div class="row mt-2 " v-if="tripDetailsState?.details && tripDetailsState?.details?.id === tripId">
                <pre
                    class="details-info__body"
                    v-html="tripDetailsState.details.detailsInfo"
                />
    </div>
  </div>

</template>

<script lang="ts">
import {defineComponent, onBeforeMount, ref} from "vue";
import {useRoute} from 'vue-router'
import tripRecordsStore from "@/store/trip-records";

export default defineComponent({
  name: 'TripAllDetailsPage',
  components: {},
  setup: function () {
    const {
      tripDetailsState,
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
    })

    return {
      tripId,
      tripDetailsState
    }
  },
})
</script>

<style scoped>

.details-info__body {
  white-space: pre-wrap;
  font-family: 'Raleway', sans-serif;
}

</style>
