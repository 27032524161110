<template>
  <div>
    <div id="2GisMap"  style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>

import gMap from '2gis-maps'

export default {
  props: {

  },
  methods: {
    init() {
      let map
      gMap.then(() => {
        // eslint-disable-next-line no-undef
        map = DG.map('2GisMap', {
          'center': [55.15943651323107, 61.40031337738038],
          'zoom': 15
        });
        // eslint-disable-next-line no-undef
        DG.marker([55.157378, 61.400312]).addTo(map).bindPopup('Zavbus - компания по организации отдыха, прокату и ремонту сноубордов, горных лыж, велосипедов');
      })
    }
  },
  created() {
    this.init()
  }
}
</script>
