
  import {defineComponent} from "vue"
  import NavigationLinks from '@/components/NavigationLinks.vue';
  import LoginInfo from '@/components/LoginInfo.vue';
  import Sidebar from '@/components/menu/Sidebar.vue';

  export default defineComponent({
    name: 'Header',
    props: {
      showLoginInfo: Boolean
    },
    components: {
      LoginInfo,
      Sidebar,
      NavigationLinks
    }
  })
