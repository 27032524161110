
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useRoute} from 'vue-router'
import tripDetailsStore from "@/store/trip-details";
import authStore from "@/store/auth";
import TripInfoComponent from "@/views/TripDetails/components/TripInfoComponent.vue"
import TripRecordComponent from "@/views/TripDetails/components/TripRecordComponent.vue"
import ConfirmTripRecordModal
  from "@/views/TripDetails/components/modals/ConfirmTripRecordModal/ConfirmTripRecordModal.vue"
import CancelTripRecordWarning from "@/views/TripDetails/components/modals/CancelTripRecordWarning.vue"
import MessageComponent from "@/views/TripDetails/components/MessageComponent.vue"
import TripRecord from "@/models/trip/TripRecord";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: 'TripDetails',
  components: {
    ConfirmTripRecordModal,
    CancelTripRecordWarning,
    TripInfoComponent,
    TripRecordComponent,
    MessageComponent,
    Loader
  },
  setup: function () {
    const {
      state,
      fetchTripDetailsAndPackets,
      editFirstTripRecordByUserData,
      getTotalPrice,
      getFullPrepaidSum,
      newTripRecord,
      resetSelectedPacket,
      removeTripRecord,
      sendTripRecords,
      payWithTripBonuses,
      clearMessage
    } = tripDetailsStore

    const {authState, authByVk, fetchUserData} = authStore

    const checkData = ref(false)
    const showModal = ref(false)
    const showCancelTripRecordModal = ref(false)
    const tripRecordErrors = ref([] as TripRecordErrors[])

    const currentRiderIndex = ref(-1)
    const currentTripRecord = ref() //показывает форму для выбранного райдера

    const tripId = ref(0)

    // const tripId = ref(+computed(() => useRoute().params.id).value);

    const setCurrentRiderIndex = (index: number) => {
      currentRiderIndex.value = index
    }

    const setCurrentTripRecord = (tripRecord: TripRecord) => {
      currentTripRecord.value = tripRecord
    }

    const newTripRecordAndSetCurrent = (
        name: string,
        surname: string,
        phone: string
    ) => {
      const newRecord = newTripRecord(name, surname, phone)

      setCurrentRiderIndex(newRecord.id)
    }

    const getTriRecordErrors = (tripRecord: TripRecord): TripRecordErrors | undefined => {
      return tripRecordErrors.value.find(err => err.tripRecord === tripRecord)
    }

    const isCorrectData = computed(() => {
      return state.tripRecords.filter(
          rp => rp.name == "" || rp.surname == "" || rp.selectedPacket.id == null
      ).length == 0
    })

    const createTripRecords = async () => {
      await authStore.fetchUserData()
      tripRecordErrors.value = [];

      state.tripRecords.forEach((it: TripRecord) => {
        const re: TripRecordErrors = TripRecordErrors.validate(it)
        if (re !== undefined && re.hasErrors) {
          tripRecordErrors.value.push(re)
        }
      })

      if (tripRecordErrors.value.length === 0) {
        showModal.value = true
      }
    }

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id

      await fetchTripDetailsAndPackets(tripId.value)

      if (state.tripRecords.length === 0) {
        newTripRecordAndSetCurrent(
            authState.userData.name,
            authState.userData.surname,
            authState.userData.phone
        )
      } else {
        resetSelectedPacket()
      }
    })

    watch(() => authState.userData.username, async (newValues, prevValues) => {
      if (newValues != null) {
        await fetchTripDetailsAndPackets(tripId.value)
        editFirstTripRecordByUserData(authState.userData)
      }
    })

    const tryRemoveTripRecord = (tripRecord: TripRecord) => {
      if (tripRecord.id > 0) {
        showCancelTripRecordModal.value = true
      } else {
        removeTripRecord(tripRecord)
      }
    }

    const trySendTripRecords = async () => {
      const resp = await sendTripRecords()

      if (resp.success) {
        showModal.value = false
        clearMessage()
      }
    }

    const closeConfirmTripRecordModal = () => {
      showModal.value = false
      clearMessage()
    }

    const getImageUrl = (id: number) => {
      return "https://zavbus.ru/api/image/displayTripImage/" + id
    }

    return {
      getImageUrl,
      tripRecordErrors,
      getTriRecordErrors,
      checkData,
      showModal,
      showCancelTripRecordModal,
      currentRiderIndex,
      setCurrentRiderIndex,
      currentTripRecord,
      isCorrectData,
      setCurrentTripRecord,
      state,
      tripId,
      getTotalPrice,
      getFullPrepaidSum,
      newTripRecordAndSetCurrent,
      tryRemoveTripRecord,
      trySendTripRecords,
      createTripRecords,
      authState,
      authByVk,
      payWithTripBonuses,
      clearMessage,
      closeConfirmTripRecordModal
    }
  },
})
