<template>
  <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
    <div class="service-box">
      <router-link :to="{name: 'service',  params: { code: zavbusService.code }}">
        <img :src="zavbusService.imagePath" class="img-fluid" alt="">
      </router-link>
      <div class="service-box__info" style="padding: 30px">
        <h4>
          <router-link :to="{name: 'service',  params: { code: zavbusService.code }}">
            {{ zavbusService.title }}
          </router-link>
        </h4>
        <p> {{ zavbusService.description }} </p>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ZavbusService from "@/models/ZavbusService";

export default defineComponent({
  name: 'Services',
  props: {
    zavbusService: {
      type: Object as PropType<ZavbusService>,
      required: true
    }
  }
})
</script>

<style>
.services .service-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  /* padding: 80px 20px; */
  transition: all ease-in-out 0.3s;
}

.service-box__info h4 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.service-box__info p {
  font-family: "Raleway", sans-serif;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

</style>
