
import {defineComponent, onBeforeMount} from "vue";
import userBonusesStore from "@/store/user-bonuses";

export default defineComponent({
  name: 'RiderBonusesPage',
  components: {},
  setup: function () {
    const {
      userBonusesState,
      fetchUserBonuses
    } = userBonusesStore


    onBeforeMount(async () => {
      await fetchUserBonuses()
    })

    return {
      userBonusesState
    }
  },
})
