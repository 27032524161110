
import {defineComponent, onBeforeMount, ref} from "vue";
import tripRecordsStore from "@/store/trip-records";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'TripRidersPage',
  components: {},
  setup() {
    const {
      tripDetailsState,
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
    })

    return {
      tripId,
      tripDetailsState
    }
  }
})
