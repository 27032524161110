<template>
  <section id="contact" class="contact section-show">
    <div class="container">

      <div class="section-title">
        <h2>Контакты</h2>
        <p>Контакты</p>
      </div>

      <div class="row mt-2">

        <div class="col-md-12 d-flex align-items-stretch">
          <div class="info-box" style="padding: 0!important;">
            <t-gis-map
                :center="[54.98, 82.89]"
                :marker="[54.98, 82.89]"
                :styles="{width: '300px', height: '200px'}"
                :zoom="16"
            ></t-gis-map>
          </div>
        </div>

        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="info-box__icon bx bx-map"></i>
            <h3>Адрес офиса</h3>
            <p>ул.Тимирязева 41, цокольный этаж</p>
          </div>
        </div>

        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="info-box__icon bx bx-share-alt"></i>
            <h3>Мы в социальных сетях</h3>
            <div class="social-links">
<!--              <a href="https://www.instagram.com/zavbus/" class="instagram">-->
<!--                <i class="bx bxl-instagram social-links__icon"/>-->
<!--              </a>-->

              <a href="https://vk.com/zavbus/">
                <i class="bx bxl-vk social-links__icon"/>
              </a>

            </div>
          </div>
        </div>

        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="info-box__icon bx bx-envelope"></i>
            <h3> Электронная почта </h3>
            <p>
              <a href="mailto:zavbus@mail.ru?subject=Feedback&body=Message">
                zavbus@mail.ru
              </a>
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 d-flex align-items-stretch">
          <div class="info-box">
            <i class="info-box__icon bx bx-phone-call"></i>
            <h3> Мы на связи </h3>
            <p>
              <a href="tel:+73517507287" class="footer-phone">
                <i class="fa fa-phone" aria-hidden="true"></i> +7 (351) 750-72-87
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import tGisMap from './tGisMap.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    tGisMap
  }
})
</script>

<style scoped>
.social-links__icon {
  font-size: 30px;
}
</style>
