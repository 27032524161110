
import {defineComponent} from "vue"
import authStore from "@/store/auth.ts"

export default defineComponent({
  name: 'LoginInfo',
  setup() {
    const {
      authState,
      authByVk,
      logout
    } = authStore

    return {
      authByVk,
      authState,
      logout
    }
  }
})
