import {reactive} from 'vue'
import UserData from "@/models/UserData";
import backendApi from "@/backend-api";
import {auth} from "@/scripts/authByVk"

const authState = reactive({
  userData: {} as UserData
})

const actions = {
  async fetchUserData() {
    authState.userData = await backendApi.userData()

    if (authState.userData.redirectUrl) {
      window.location.href = authState.userData.redirectUrl
    }

    actions.setRiderNameToYaMetrika(authState.userData.riderFullName)
  },
  async setRiderNameToYaMetrika(riderFullName: string) {
    if ((window as any).Ya && riderFullName) {
      (window as any).Ya._metrika.counter.params({
        riderFullName: riderFullName
      })
    }
  },
  async authByVk() {
    auth(authState.userData.vkLink, actions.fetchUserData)
  },
  async logout() {
    await backendApi.logout()
    actions.fetchUserData()
  }
}

export default {
  authState,
  ...actions,
}

