
  import {defineComponent} from "vue"
  import AppLayout from "@/layouts/AppLayout.vue"

  export default defineComponent({
    name: 'app',
    components: {
      AppLayout
    },
  });
