
import {computed, defineComponent, PropType, ref, watch} from 'vue'
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import TripRecord from "@/models/trip/TripRecord";

export default defineComponent({
  name: 'FieldErrorIconComponent',
  props: {
    tripRecordField: {
      type: String as PropType<keyof TripRecord>,
      required: true
    },
    tripRecordErrors: {
      type: Object as PropType<TripRecordErrors>,
      required: false
    },
  },
  setup(props, {emit}) {
    const showIcon = ref(false)

    watch(
        () =>  props.tripRecordErrors?.errorFields,
        (errs, prevState) => {
          showIcon.value = errs?.find(it => it === props.tripRecordField) !== undefined
        }
    )

    showIcon.value = computed(() => props.tripRecordErrors?.errorFields).value
        ?.find(it => it === props.tripRecordField) !== undefined

    return {
      showIcon
    }
  }
})
