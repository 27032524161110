import {reactive} from 'vue'
import backendApi from "@/backend-api";
import UserBonuses from "@/models/UserBonuses";

const userBonusesState = reactive({
  bonuses: [] as UserBonuses[]
})

const actions = {
  async fetchUserBonuses() {
    const resp = await backendApi.userBonuses()
    userBonusesState.bonuses = resp.txList
  }
}

export default {
  userBonusesState,
  ...actions,
}

