<template>
  <ul>
    <li>
      <router-link :to="{ name: 'tripList' }" class="link">Выезды</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'services' }" class="link">Прокат и Сервис</router-link>
    </li>
    <li>
      <router-link :to="{ name: 'contacts' }" class="link"> Контакты </router-link>
    </li>
    <li v-if="authState.userData.username">
      <router-link :to="{ name: 'tripHistory' }" class="link"> История поездок </router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import authStore from "@/store/auth";

export default defineComponent({
  name: 'NavigationLinks',
  setup() {
    const {
      authState
    } = authStore

    return {
      authState
    }
  }
})
</script>

<style>
.link {
  font-size: 20px;
}
</style>
