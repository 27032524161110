<template>

  <section id="trip" class="trip section-show" style="height: auto!important;">
    <div class="container" >

      <div v-if="authState.userData.username">
        <h2> Ваша история поездок </h2>

        <table class="table table-dark">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Направление</th>
            <th>Программа</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(tripHistoryItem, index) in tripHistoryState.list" :key="index" class="trip-history-row">
            <td>{{ tripHistoryItem.tripDate }}</td>
            <td>{{ tripHistoryItem.tripName }}</td>
            <td>
              {{ tripHistoryItem.tripPacketName }}
              <span class="trip-history-row__absence" v-if="!tripHistoryItem.confirmed"> - вы не пришли на выезд<sup>*</sup></span>
            </td>
          </tr>
          </tbody>
        </table>

        * Отсутствие на выезде может быть причиной нахождения в предоплатном списке
      </div>
    </div>

  </section>

</template>

<script lang="ts">
import {defineComponent, onBeforeMount} from "vue";
import userTripHistoryStore from "@/store/user-trip-history.ts";
import {useRoute} from "vue-router";
import authStore from "@/store/auth";

export default defineComponent({
  name: 'TripHistoryPage',
  components: {},
  setup: function () {
    const {
      tripHistoryState,
      fetchUserTripHistory
    } = userTripHistoryStore

    const {
      authState
    } = authStore

    onBeforeMount(async () => {
      await fetchUserTripHistory()
    })

    return {
        authState,
      tripHistoryState
    }
  },
})
</script>

<style scoped>
.trip-history-row__absence {
  color: #d9534f !important;
}
</style>


