<template>
  <section id="trip" class="trip section-show" style="height: auto!important;">
    <div class="container">

      <div>
        <h2> Бонусные начисления </h2>

        <table class="table table-dark">
          <thead>
          <tr>
            <th></th>
            <th>Дата начисления</th>
            <th>Сумма</th>
            <th>Причина</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(tx, index) in userBonusesState.bonuses" :key="index">
            <td>
              <span class="bx bxs-chevron-up text-green" v-if="tx.isPositive"/>
              <span class="bx bxs-chevron-down text-green" v-else/>
            </td>
            <td>{{ tx.date }}</td>
            <td>{{ tx.sum }}</td>
            <td>{{ tx.reason }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>

</template>

<script lang="ts">
import {defineComponent, onBeforeMount} from "vue";
import userBonusesStore from "@/store/user-bonuses";

export default defineComponent({
  name: 'RiderBonusesPage',
  components: {},
  setup: function () {
    const {
      userBonusesState,
      fetchUserBonuses
    } = userBonusesStore


    onBeforeMount(async () => {
      await fetchUserBonuses()
    })

    return {
      userBonusesState
    }
  },
})
</script>

<style scoped>


</style>
