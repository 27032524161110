<template>
  <section id="trip" class="trip section-show" style="height: auto!important;">
    <div class="container">
      <Loader v-if="tripId !== tripDetailsState?.details?.id"/>
      <router-view v-else></router-view>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import tripRecordsStore from "@/store/trip-records";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: 'TripPageLayout',
  components: {Loader},
  setup: function () {
    const {
      tripDetailsState,
      fetchTripDetails
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
      await fetchTripDetails(tripId.value)
    })

    return {
      tripId,
      tripDetailsState
    }
  },
})
</script>

