import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import AppLayout from "@/layouts/AppLayout.vue";
import ContentLayout from "@/layouts/ContentLayout.vue";
import FirstLayout from "@/layouts/FirstLayout.vue";

import VueFinalModal from 'vue-final-modal'
import TripPageLayout from "@/views/TripPageLayout.vue";

createApp(App)
    .use(store)
    .use(router)
    .use(VueFinalModal())
    .component('AppLayout', AppLayout)
    .component('ContentLayout', ContentLayout)
    .component('TripPageLayout', TripPageLayout)
    .component('FirstLayout', FirstLayout)
    .mount('#app')
