<template>

  <div class="trip-record">
    <div class="trip-block" v-show="state.tripRecords.length > 0">
      <div class="text-right">
        <TripRecordTitleComponent
            :trip-details="state?.trip?.details"
            :trip-record-index="tripRecordIndex"
            :trip-record="tripRecord"
            :trip-record-errors="tripRecordErrors"
            :show-details="showDetails"
            @onSetCurrentRiderIndex="$emit('onSetCurrentRiderIndex')"
            @onRemoveRider="$emit('onRemoveRider')"
        />
      </div>
    </div>

    <div v-if="showDetails" class="trip-record-details">
      <div class="trip-block">
        <!--        <h3>-->
        <!--          Программы {{ packetsFor(rider) }}-->
        <!--        </h3>-->
        <div class="row">
          <TripPacketComponent
              v-for="packet in state.trip.packets"
              :key="packet.id"
              :packet="packet"
              :selectedPacket="tripRecord.selectedPacket"
              @click="setSelectedPacket(packet)"
              class=""
          />
        </div>

        <TripMustHaveServicesComponent
            class="trip-record__must-have-services"
            :selected-packet="tripRecord.selectedPacket"
            v-if="tripRecord.selectedPacket.services?.filter(it => it.mustHave)"
        />

      </div>

      <div class="trip-block" v-if="tripRecord.selectedPacket.services?.filter(it => !it.mustHave)?.length > 0">
        <h3> Дополнительно </h3>
        <div class="row trip-services">
          <ServiceInTripComponent
              v-for="service in tripRecord.selectedPacket.services?.filter(it => !it.mustHave)"
              :key="service.id"
              :service="service"
              :is-selected-service="isSelectedService(service)"
              @click="setSelectedService(service)"
              class="mb-2 d-flex"
          />
        </div>
      </div>

      <div class="trip-block" v-if="state.trip?.details?.openedCheckIn && isLoggedIn">
        <h3>
          Информация об участнике
        </h3>
        <div class="row trip-record-form">
          <div class="form-group">
            <label for="riderSurname">
              Фамилия
              <FieldErrorIconComponent
                  :trip-record-errors="tripRecordErrors"
                  :trip-record-field="'surname'"
              />
            </label>
            <input
                :value="tripRecord.surname"
                @input="updateField('surname', $event.target.value)"
                id="riderSurname"
                type="text"
                class="form-control"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Введите фамилию'"
            >
          </div>
          <div class="form-group">
            <label for="riderName">
              Имя
              <FieldErrorIconComponent
                  :trip-record-errors="tripRecordErrors"
                  :trip-record-field="'name'"
              />
            </label>
            <input
                :value="tripRecord.name"
                @input="updateField('name', $event.target.value)"
                id="riderName"
                type="text"
                class="form-control"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Введите имя'"
            >
          </div>
          <div class="form-group">
            <label for="riderPhone">
              Номер телефона
            </label>
            <input
                :value="tripRecord.phone"
                @input="updateField('phone', $event.target.value)"
                id="riderPhone"
                type="text"
                class="form-control"
                onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Введите номер телефона'"
            >
          </div>

          <div class="form-group">
            <label for="exampleTextarea">
              Комментарий
            </label>
            <textarea class="form-control"
                      @input="updateField('comment', $event.target.value)"
                      id="exampleTextarea"
                      rows="4"
                      :value="tripRecord.comment"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Здесь вы можете оставить любой комментарий'"
                      placeholder="Здесь вы можете оставить любой комментарий"></textarea>
          </div>
        </div>

        <h3 v-if="needRent">
          Информация для проката
        </h3>
        <div class="row trip-record-form" v-if="needRent">
          <div class="form-group">
            <label for="riderHeight">
              Рост
            </label>
            <input
                :value="tripRecord.height"
                @input="updateField('height', $event.target.value)"
                id="riderHeight"
                type="text"
                class="form-control"
            >
          </div>
          <div class="form-group">
            <label for="riderWeight">
              Вес
            </label>
            <input
                :value="tripRecord.weight"
                @input="updateField('weight', $event.target.value)"
                id="riderWeight"
                type="text"
                class="form-control"
            >
          </div>
          <div class="form-group">
            <label for="riderFootSize">
              Размер ноги
            </label>
            <input
                :value="tripRecord.footSize"
                @input="updateField('footSize', $event.target.value)"
                id="riderFootSize"
                type="text"
                class="form-control"
            >
            <small id="s121" class="form-text text-muted">Ваш российский размер ноги</small>
          </div>
          <div class="form-group">
            <label for="riderHeight">
              Углы креплений (только для сноуборда)
            </label>
            <input
                :value="tripRecord.angles"
                @input="updateField('angles', $event.target.value)"
                id="riderAngles"
                type="text"
                class="form-control"
            >
            <small class="form-text text-muted">Если хотите, чтобы мы сразу настроили нужные углы</small>
          </div>
        </div>
      </div>

<!--      <div class="trip-block" v-show="state.tripRecords.length !== 1">-->
<!--        <div class="text-right">-->
<!--          <button class="btn trip-record-btn" @click="confirmCurrentRider">-->
<!--            Подтвердить выбор-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from 'vue'
import TripRecordTitleComponent from "@/views/TripDetails/components/TripRecordTitleComponent.vue";
import ServiceInTripComponent from "@/views/TripDetails/components/ServiceInTripComponent.vue";
import TripPacketComponent from "@/views/TripDetails/components/TripPacketComponent.vue";
import TripRecord from "@/models/trip/TripRecord";
import tripDetailsStore from "@/store/trip-details";
import TripRecordErrors from "@/models/trip/TripRecordErrors";
import TripPacket from "@/models/trip/TripPacket";
import ServiceInTrip from "@/models/trip/ServiceInTrip";
import FieldErrorIconComponent from "@/views/TripDetails/components/FieldErrorIconComponent.vue";
import _ from 'lodash'
import TripMustHaveServicesComponent from "@/views/TripDetails/components/TripMustHaveServicesComponent.vue";

export default defineComponent({
  name: 'TripRecord',
  props: {
    tripRecordIndex: {
      type: Number,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    },
    tripRecordErrors: {
      type: Object as PropType<TripRecordErrors>,
      required: false
    },
    tripRecord: {
      type: Object as PropType<TripRecord>,
      required: false
    },
    isLoggedIn : {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:tripRecord', 'onConfirmTripRecord'],
  methods: {
    confirmCurrentRider() {
      this.$emit('onConfirmTripRecord')
    }
  },
  components: {
    TripRecordTitleComponent,
    TripPacketComponent,
    ServiceInTripComponent,
    FieldErrorIconComponent,
    TripMustHaveServicesComponent
  },
  setup(props, {emit}) {
    const {
      state
    } = tripDetailsStore

    const needRent = ref(false)

    watch(() => _.cloneDeep(props.tripRecord), (it) => {
      if (it != null) {
        needRent.value = it.selectedServices.some(it => it.rent)
            || it.selectedPacket?.services?.some(it => it.mustHave && it.rent)
      }
    })

    const updateField = (key: keyof TripRecord, value: any) => {
      emit('update:tripRecord', {...props.tripRecord, [key]: value})
    }

    const setSelectedPacket = (selectedPacket: TripPacket) => {
      emit('update:tripRecord', {
        ...props.tripRecord,
        ["selectedPacket"]: selectedPacket,
        ["selectedServices"]: []
      })
    }

    const setSelectedService = (selectedService: ServiceInTrip) => {
      const index = props.tripRecord?.selectedServices?.indexOf(selectedService)

      if (index === undefined) {
        return
      }

      if (index < 0) {
        props.tripRecord?.selectedServices.push(selectedService)
      } else {
        props.tripRecord?.selectedServices.splice(index, 1);
      }
    }

    const isSelectedService = (service: ServiceInTrip): boolean => {
      const index = props.tripRecord?.selectedServices?.indexOf(service)

      return index !== undefined && index > -1;
    }

    onMounted(async () => {
      if (state.trip.packets.length === 1) {
        setSelectedPacket(state.trip.packets[0])
      }
    })

    return {
      state,
      isSelectedService,
      setSelectedPacket,
      setSelectedService,
      updateField,
      needRent
    }
  }
})
</script>

<style scoped>
input, textarea {
  border: 0px;
  background: rgba(204, 204, 204, 0.1);
  padding: 5px 5px;
  color: #18d26e;
}

input:focus, textarea:focus {
  color: #000000;
  background-color: #18d26e;

  box-shadow: 0 0 3px #719ECE;
}

.trip-record-confirm-btn {
  vertical-align: center;
  text-align: center;
  color: #18d26e;
  font-size: 20px;
}

.trip-record-btn {
  color: #000;
  background: #18d26e;
  margin-left: 10px;
}

.trip-record {
  border-radius: 0.25rem;
  padding: 20px;
  background: rgba(204, 204, 204, 0.1);
  margin-bottom: 2rem;
}

.trip-record-details {
  /*background: rgba(204, 204, 204, 0.1);*/
}

.trip-record-form {
  margin-bottom: 15px;
}

/*.trip-record-details {*/
/*  margin-top: 30px;*/
/*}*/

@media only screen and (max-width: 768px) {
  .trip-record__must-have-services {
    display: none;
  }

}

</style>
