
import {defineComponent, PropType} from 'vue'
import TripDetailsAndPackets from "@/models/trip/TripDetailsAndPackets";

export default defineComponent({
  name: 'TripInfoComponent',
  props: {
    trip: {
      type: Object as PropType<TripDetailsAndPackets>,
      required: true
    }
  }
})
