export function auth(link: string, callback: () => {}) {

  const win = window.open(
      link,
      'SomeAuthentication',
      'width=972,height=660,modal=yes,alwaysRaised=yes'
  );

  const checkConnect = setInterval(function () {
    if (!win || !win.closed) {
      return;
    }
    clearInterval(checkConnect);
    callback()
  }, 100);

  return false;
}
