
import {defineComponent, PropType} from 'vue'
import ServiceInTrip from "@/models/trip/ServiceInTrip";

export default defineComponent({
  name: 'ServiceInTripComponent',
  props: {
    service: {
      type: Object as PropType<ServiceInTrip>,
      required: true
    },
    isSelectedService: {
      type: Boolean,
      required: true
    }
  }
})
