<template>

  <div>
    <h2>
      <router-link :to="{name: 'tripDetails', params: { id: tripDetailsState?.details?.id }}">
        {{ tripDetailsState?.details?.name }} - {{ tripDetailsState?.details?.tripDates }}
      </router-link>
    </h2>

    <table class="table table-dark">
      <thead>
      <tr>
        <th>Райдер</th>
        <th>Выбранная программа</th>
        <th class="text-center">Предоплата</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(tr, index) in tripDetailsState.riders"
          :key="index"
      >
        <td :class="{ 'trip-record__owner': tr.isOwner }"> {{ tr.riderName }}</td>
        <td :class="{ 'trip-record__owner': tr.isOwner }"> {{ tr.packetName }}</td>
        <td class="text-center">
            <span v-if="tr.isOwner">
              <span v-if="tr.inPrepaidList">Необходима предоплата</span>
              <span v-else>{{ tr.displayedPrepaidSum }}</span>
            </span>
          <i
              v-if="!tr.isOwner && tr.hasPrepaidSum"
              class="bx bxs-badge-dollar"
              style="color: #4ceb95;"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount, ref} from "vue";
import tripRecordsStore from "@/store/trip-records";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'TripRidersPage',
  components: {},
  setup() {
    const {
      tripDetailsState,
    } = tripRecordsStore

    const tripId = ref(0)

    onBeforeMount(async () => {
      tripId.value = +useRoute().params.id
    })

    return {
      tripId,
      tripDetailsState
    }
  }
})
</script>

<style scoped>
.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e;
}

.trip-record__owner {
  color: #18d26e;
}

</style>
